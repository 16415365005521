import React from 'react';
import styled from '@emotion/styled';
import useFiltro from '../hooks/useFiltro';
import Layout from '../components/layout';
import ListadoProductos from '../components/productosComponents/listadoProductos';

import useResponsive from '../hooks/useResponsive';
const {size,device,ScreenWidth} = useResponsive();

const ProductsContainer = styled.section`
    padding:5% 10%;
    min-height:calc(100vh - 12rem);
    @media ${device.mobileMed}{
        padding:5% 5%;
    }
    h1,h2{
        line-height:2.5rem;
    }
    .productosHeader{
        display:flex;
        justify-content:space-between;
        background-color:#333;
        border-radius:5px;
        margin-bottom:2rem;
        background-color:hsl(210, 15%, 15%);
        border-top:5px solid var(--s1color);
        animation: fadeIn 1s linear;
    }
    .productosHeader-title{
        padding:2rem;
        clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
        background-color:#eaeaea;
        min-width:35%;
        @media ${device.mobileAll}{
            min-width:50%;
            h1{
                font-size:2.4rem;
            }
            h2{
                font-size:1.5rem;
            }
        }
        h1,h2{
            color:var(--bgcolor);
        }
        
    }
    .productosHeader-icons{
        padding:1rem 3rem;
        display:flex;
        align-items:center;
        justify-content:flex-end;
        min-width:50%;
        opacity:0;
        animation: fadeIn 1s linear;
        animation-delay:1s;
        animation-fill-mode:forwards;
        span{
            width:6rem;
            height:6rem;
            display:flex;
            justify-content:center;
            align-items:center;
            margin-right:1rem;
            border-radius:3px;
            &:last-child{
                margin-right:0;
            }
        }
    }
`;

export default function Productos() {

    const Width = ScreenWidth();
    const { categoria, FiltroUI, FiltroBTN } = useFiltro();

    return (
        <Layout>
            <ProductsContainer>
                <div className='productosHeader'>

                    <div className='productosHeader-title'>
                        <h1>Productos</h1>
                        <h2>SGL - Serie Gold</h2>
                    </div>

                    <div className='productosHeader-icons'>
                        {(Width > size.mobile_max) ? FiltroBTN() : FiltroUI()}
                    </div>
                    
                </div>
                <ListadoProductos 
                    ctg={categoria}
                />
            </ProductsContainer>
        </Layout>
    );
}
