import React, {useState} from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import styled from '@emotion/styled';


const Filtrado = styled.form`
    width:100% ;
    display:flex;
    border:1px solid #e1e1e1;
    border-radius:3px;
`;
const Selector = styled.select`
        flex:1;
        padding:1rem;
        appearance: none;
        border:none;
        &:hover{
            cursor: pointer;
        }
        option {
            padding:1rem;
        }
    `;

const BtnFilter = styled.button`
    padding:2rem;
    border:0;
    border-radius:2px;
    background-color:transparent;
    outline:0 !important;
    color:var(--s1color);
    transition: all .3s;
    font-weight:bold;
    &:hover{
        cursor: pointer;
        background-color:#ccc;
    }
    &.active{
        background-color:#ccc;
        color:#111;
       
    }
`;

export default function useFiltro() { 
    
    const [categoria, guardarCategoria] = useState('');

    const resultado = useStaticQuery(graphql`
          query{
            allStrapiCategorias {
                nodes {
                    Nombre
                    id
                }
            }
        }
    `);

    const categorias = resultado.allStrapiCategorias.nodes;
    
    
    const FiltroUI = () => ( 
        <Filtrado>
            <Selector
                onChange={ e => guardarCategoria(e.target.value) }
                value={categoria}
            >  
                <option value="">Todos</option>
                {categorias.map(opcion => (
                    <option key={opcion.id}>{opcion.Nombre}</option>
                ))}
            </Selector>
        </Filtrado>
    )
    
    const FiltroBTN = () => (
        <div>
            {categorias.map(opcion => (
        
                <BtnFilter
                    onClick={ e => guardarCategoria(e.target.value) }
                    value={opcion.Nombre} 
                    key={opcion.id}
                    className={(opcion.Nombre===categoria) ? 'active' : ''}
                >
                {opcion.Nombre}
                </BtnFilter>
            ))}
            <BtnFilter className={(categoria==='') ? 'active' : ''}
            onClick={ e => guardarCategoria(e.target.value) } value=""> Todos </BtnFilter>
        </div>
    )
    return {
        categoria,
        FiltroUI,
        FiltroBTN
    }

}